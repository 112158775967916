@import 'src/assets/styles/mixins'

.NotFound
  position: relative
  min-height: 100vh

  &__main
    position: relative
    display: flex
    flex-direction: column
    align-items: center
    padding: 80px 0 30px
    z-index: 1

    +screen_sm_max
      padding: 40px 15px 20px

  &__bg-left
    position: absolute
    left: 0
    bottom: 0
    z-index: 0
    display: none

    @media screen and (min-width: 1025px) and (min-height: 880px)
      display: block

  &__bg-right
    position: absolute
    right: 0
    bottom: 0
    z-index: 0
    display: none

    @media screen and (min-width: 1025px) and (min-height: 880px)
      display: block

  &__logo
    display: block
    margin-bottom: 40px

    +screen_rwd(1025)
      margin-bottom: 92px

  &__img-404
    margin-bottom: 30px
    width: 264px
    height: 225px

    +screen_rwd(1025)
      margin-bottom: 50px
      height: 245px

  &__title
    +text-heading-h1
    margin-bottom: 24px
    color: var(--black)

    +screen_sm_max
      font-size: 24px

  &__desc
    +text-body-m
    max-width: 405px
    width: 100%
    margin: 0 auto 32px
    color: var(--black)
    text-align: center

  // .NotFound__button
  &__button.MuiButtonBase-root
    +buttons-green
    +buttons-l
