=font-button-s
  /* buttons / S */
  font-family: var(--Inter)
  font-size: 13px
  font-style: normal
  font-weight: 700
  line-height: 18px /* 138.462% */
  letter-spacing: 0.2px

=font-button-sm
  /* buttons / SM */
  font-family: var(--Inter)
  font-size: 14px
  font-style: normal
  font-weight: 700
  line-height: 18px /* 138.462% */
  letter-spacing: 0.3px

=font-button-m
  /* buttons / M */
  font-family: var(--Inter)
  font-size: 15px
  font-style: normal
  font-weight: 700
  line-height: 20px /* 133.333% */
  letter-spacing: 0.4px

=font-button-l
  /* buttons / L */
  font-family: var(--Inter)
  font-size: 15px
  font-style: normal
  font-weight: 700
  line-height: 20px /* 133.333% */
  letter-spacing: 0.5px
