@import 'assets/styles/mixins'

.LoaderForData
  // position: relative
  height: 100%
  width: 100%
  transition: .4s
  // display: flex
  // justify-content: center
  // align-items: center

  // .LoaderForData__WrapIcon
  &__WrapIcon
    position: absolute
    // background-color: red
    transform: translate(-50%, -50%)
    top: 50%
    left: 50%

  &.opacityMode
    flex: 1 1 auto
    .LoaderForData__WrapIcon
      opacity: 0
      visibility: hidden
      transition: visibility 0s linear 1.4s, opacity 1.3s

    .opacity-on
      opacity: 1
      transition: opacity .8s

    &.opacity
      pointer-events: none
      .LoaderForData__WrapIcon
        opacity: 1
        visibility: visible
        transition: visibility 0s linear 0s, opacity .8s
      .opacity-on
        opacity: 0.4

  &.fullScreen
    position: fixed
    z-index: 99999
    top: 0
    left: 0
    height: 100vh
    width: 100vw
    // background-color: rgba(var(--rgb-black), 0.3)
