@use 'sass:selector'

@import './base'
@import 'src/assets/styles/breakpoints'
@import 'src/assets/styles/text-mixins'
@import 'src/assets/styles/buttons-mixins'
@import 'src/assets/styles/components'
@import 'src/assets/styles/general'

// add selector to first parent
=first-parent($child)
  @at-root #{selector.unify(&, $child)}
    @content

=input-hover
  transition: .3s
  &:hover
    box-shadow: 0px 0px 7px 2px lightgrey

=dropdown
  background: var(--white) !important
  box-shadow: 0 34px 60px rgba(0, 0, 0, 0.1) !important
  border-radius: 10px !important

=ellipsis
  white-space: nowrap
  text-overflow: ellipsis
  overflow: hidden

=ellipsis-multi($column: 3)
  overflow: hidden
  display: -webkit-box
  -webkit-line-clamp: $column
  -webkit-box-orient: vertical
  word-break: break-word

=ellipsis-wrap
  text-overflow: ellipsis
  overflow: hidden

=ellipsis-root-right($backgroundColor: inherit)
  background-color: $backgroundColor
  min-width: 0px
  .ellipsis-extend
    @include ellipsis
    width: 100%
    background-color: $backgroundColor
  &:hover
    .ellipsis-extend
      position: relative
      padding-right: 8px
      border-radius: 6px
      width: max-content
      z-index: 1111
      // white-space: unset
      // width: auto

=ellipsis-root-down($backgroundColor: inherit)
  +ellipsis-root-right($backgroundColor)
  &:hover
    .ellipsis-extend
      white-space: unset
      width: auto

=dropdown-opened-scaleY
  box-shadow: 0 34px 60px rgba(0, 0, 0, 0.1)
  transform: scaleY(1)
  visibility: visible

=dropdown-opened-scaleX
  box-shadow: 0 34px 60px rgba(0, 0, 0, 0.1)
  transform: scaleX(1)
  visibility: visible

=imageHover($border: 12px, $borderHover: 4px, $scale: 1.1, $time: 0.4s)
  overflow: hidden
  transition: $time
  border-radius: $border
  &:hover
    border-radius: $borderHover
    img
      transform: scale($scale)
  img
    transition: $time

=imageHoverSmall
  +imageHover($border: 8px, $borderHover: 3px, $scale: 1.15, $time: .3s)
  
