@import 'src/assets/styles/mixins'

.InfoPopup
  z-index: 2147483644

  &.MuiSnackbar-root
    white-space: pre-line
    bottom: 90px
    +screen_sm
      right: Unit(3)

    .MuiSnackbarContent-message
      line-height: 28px
      padding: 4px 0px

  .MuiSnackbarContent-root
    +fontText-m
    box-shadow: 0 10px 20px -8px var(--superBlack)
    border-radius: 10px
    padding: Unit(1.5) Unit(2.5)


  .snackbar
    &_success
      &.MuiSnackbarContent-root
        background-color: var(--success)
        .DarkMode &
          color: var(--black)
      .InfoPopup__link
        color: var(--gray300)
        &:hover
          color: var(--white)

    &_error
      &.MuiSnackbarContent-root
        background-color: var(--error)
        .DarkMode &
          color: var(--black)

    &_info
      &.MuiSnackbarContent-root
        background-color: var(--secondary)

    &_black
      &.MuiSnackbarContent-root
        background-color: var(--black)
        .InfoPopup__link
          color: var(--white)


    &_link
      &.MuiSnackbarContent-root
        background-color: var(--link)
        .DarkMode &
          color: var(--black)

        .InfoPopup__link
          color: var(--white)



  // .InfoPopup__button
  &__button
    border-radius: 8px
    &.MuiButton-textPrimary
      .DarkMode .snackbar_link &
        color: var(--gray400)

      &:hover
        background-color: var(--gray800)
        color: var(--gray300)
        // .DarkMode .snackbar_black &
        //   background-color: var(--red)


  // .InfoPopup__iconClose
  &__iconClose
    .snackbar_link &
      color: var(--gray500)
    .DarkMode .snackbar_info &
      color: var(--gray400)
      &:hover
        color: var(--red)
    &:hover
      color: var(--red)
      background-color: var(--gray800) !important


  // .InfoPopup__link
  &__link
    +font-body-xs
    transition: 0.2s
    text-transform: lowercase
    margin-right: 8px
    text-decoration: none
    color: var(--link)
    opacity: .8
    // background: var(--gray400)
    &:hover
      // color: var(--black)
      // background: var(--gray800)
      text-decoration: underline
      opacity: 1
    +screen_sm_max
      margin-right: 24px
