=buttons-base
  transition: 0.5s
  text-transform: inherit
  text-decoration: none

=buttons-green
  +buttons-base
  background: var(--primaryLight)
  color: var(--white)
  .DarkMode &
    color: var(--black)
  &:disabled
    background-color: var(--gray500)
  &:hover
    background: var(--primaryDarken)
    color: var(--white)

=buttons-cta
  +buttons-base
  background-color: var(--CTA)
  color: var(--white)
  .DarkMode &
    color: var(--black)
  &:hover
    background-color: var(--CTAHover)


=buttons-gray
  +buttons-base
  background: var(--gray400)
  color: var(--gray800)
  &:hover
    background: var(--primary)
    color: var(--white)

=buttons-gray-cta
  +buttons-base
  background: var(--gray400)
  color: var(--gray800)
  &:hover
    background: var(--CTA)
    color: var(--white)

=buttons-mobile
  +screen-sm-max
    width: 100%

=buttons-s
  +font-button-s
  +borderRadius-s
  padding: 11px 16px

=buttons-sm
  +font-button-sm
  +borderRadius-m
  padding: 11px 16px

// 15/20 + 14 * 2 = 48
=buttons-m
  +font-button-m
  +borderRadius-m
  +height-m
  padding: 14px 16px

=buttons-l
  +font-button-l
  +borderRadius-m
  +height-l
  padding: 18px 24px
  border-radius: 12px
  // +screen-sm-max
  //   width: 100%

=buttons-mobile
  +screen-sm-max
    width: 100%

=buttons-l-mobile-m
  +font-button-l
  +borderRadius-m
  padding: 18px 24px
  +screen-sm-max
    +font-button-m
    width: 100%
    padding: 14px 16px
    border-radius: 12px

=buttons-upload-files
  +text-button-m
  +height-m
  +borderRadius-m
  display: inline-block
  line-height: $height-m
  min-width: 160px
  color: var(--white)
  background: var(--primary)
  text-align: center
  cursor: pointer
  flex-shrink: 0
  &:hover
    background: var(--primaryDarken)
    