@import "assets/styles/mixins"

.LoadingButton
    display: none
    .cirular-progress
        position: absolute
    // .LoadingButton__button
    .LoadingButton__button // Fix: oredirng styles
        +buttons-green
        +buttons-l
