@mixin font-heading-hero {
  text-align: center;

  /* heading / hero */
  font-family: var(--TT);
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 68px; /* 106.25% */
  letter-spacing: -0.64px;
}

@mixin font-heading-hero-375 {
  text-align: center;

  /* heading/hero-375 */
  font-family: var(--TT);
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px; /* 118.75% */
  letter-spacing: -0.32px;
}

@mixin font-heading-h1 {
  text-align: center;

  /* heading/H1 */
  font-family: var(--TT);
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

@mixin font-heading-h1-375 {
  text-align: center;
  
  /* heading/H1-375 */
  font-family: var(--TT);
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 120% */
}

@mixin font-heading-h2 {
  /* heading/H2 */
  font-family: var(--TT);
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 70px; /* 140% */
}

@mixin font-heading-h3 {
  /* heading/H3 */
  font-family: var(--TT);
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px; /* 119.048% */
}


@mixin font-heading-h3-375 {
  /* heading/H3-375 */
  font-family: var(--TT);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 133.333% */
}

@mixin font-heading-h4 {
  /* heading/H4 */
  font-family: var(--TT);
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

@mixin font-heading-h5 {
  /* heading/H5 */
  font-family: var(--TT);
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px; /* 121.429% */
}

@mixin font-heading-h6 {
  /* heading / H6 */
  font-family: var(--TT);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 133.333% */
}

@mixin font-h19px {
  font-family: var(--TT);
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

@mixin font-h2 {
  @include font-heading-h2;
}
@mixin font-h4 {
  @include font-heading-h4;
}
@mixin font-h5 {
  @include font-heading-h5;
}
@mixin font-h6 {
  @include font-heading-h6;
}
