@import "./variables";

// Small devices
@mixin screen_xs {
  @media (min-width: #{$screen-xs-min}) {
    @content;
  }
}
@mixin screen_xs_max {
  @media (max-width: #{$screen-xs-min}) {
    @content;
  }
}
@mixin screen_iphone12pro {
  @media (min-width: #{$screen-iphone12pro-min}) {
    @content;
  }
}
@mixin screen_iphone12pro_max {
  @media (max-width: #{$screen-iphone12pro-min}) {
    @content;
  }
}
@mixin screen_iphone12pro_not_under {
  @media (max-width: #{$screen-iphone12pro-min - 0.5}) {
    @content;
  }
}
@mixin screen_sm {
  @media (min-width: #{$screen-sm-min + 1}) {
    @content;
  }
}
@mixin screen_sm_max {
  @media (max-width: #{$screen-sm-min}) {
    @content;
  }
}

// Medium devices
@mixin screen_md {
  @media (min-width: #{$screen-md-min + 1}) {
    @content;
  }
}
@mixin screen_md_max {
  @media (max-width: #{$screen-md-min}) {
    @content;
  }
}

// Large devices
@mixin screen_lg {
  @media (min-width: #{$screen-lg-min + 1}) {
    @content;
  }
}

@mixin screen_lg_max {
  @media (max-width: #{$screen-lg-min}) {
    @content;
  }
}

// Extra large devices
@mixin screen_xl {
  @media (min-width: #{$screen-xl-min + 1}) {
    @content;
  }
}

// Custom devices
@mixin screen_rwd($screen) {
  @media (min-width: #{$screen}px) {
    @content;
  }
}
@mixin screen_rwd_max($screen) {
  @media (max-width: #{$screen}px) {
    @content;
  }
}
