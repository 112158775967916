@import 'assets/styles/mixins'

.Logo
  color: var(--black)
  position: relative
  display: inline-block
  // .Logo__wrap
  &__wrap
    text-decoration: none
    display: flex
  .subscription-plan
    padding-right: 1px
    font-size: 10px
    line-height: 10px
    color: var(--gray800)
    position: absolute
    right: 0px
    bottom: -3px
    +screen_rwd_max(319)
      display: none
