@import 'src/assets/styles/mixins'

.HandshakeFilter
    position: relative
    border-radius: 10px
    @include screen_sm
        display: block
    &.disabled
        pointer-events: none
    &.opened
        box-shadow: 0px 0px 2px 2px var(--grayC5)
    &.closed
        .HandshakeFilter__dropdown-wrap
            display: none

    &.opened
        box-shadow: 0px 0px 2px 2px var(--grayC5)
        .HandshakeFilter__button-icon_down
            transform: rotate(180deg)
            color: var(--white)
        .HandshakeFilter__button
            background-color: var(--gray500)
            svg
                color: var(--primary)
        .HandshakeFilter__dropdown-wrap
            +dropdown-opened-scaleY
    @media(hover: hover)
        &:hover
            .HandshakeFilter__button-icon_down
                transform: rotate(180deg)
                color: var(--white)
            .HandshakeFilter__button
                background-color: var(--gray500)
                svg
                    color: var(--primary)
            .HandshakeFilter__dropdown-wrap
                +dropdown-opened-scaleY


    // .HandshakeFilter__button
    &__button
        display: flex
        align-items: center
        // padding: Unit(1) Unit(2)
        gap: 1 * $unit
        cursor: pointer
        border-radius: 6px
        transition: 0.5s
    // .HandshakeFilter__dropdown-wrap
    &__dropdown-wrap
        // padding-top: $unit
        top: 40px
        right: 6px
        position: absolute
        z-index: 1000
        transform: scaleY(0)
        visibility: hidden
        transition: 0.3s ease
        transform-origin: top left
        border-radius: 10px
        border: 1px solid var(--grayE8)
        +screen_rwd_max(600)
            right: 2 * $unit
            left: unset

    // .HandshakeFilter__dropdown
    &__dropdown
        padding: $unit 0
        width: 240px
        // min-width: 160px
        background: var(--white)
        display: flex
        flex-direction: column
        border-radius: 10px
        +screen_rwd_max(360)
            width: 100%
            min-width: 220px
        // .HandshakeFilter__dropdown-item
        &-item
            +font-body-m
            padding: $unit $unit $unit 2 * $unit
            display: flex
            gap: $unit
            align-items: center
            cursor: pointer
            transition: background-color .3s, padding .4s
            &.selected
                background-color: var(--gray300)
                pointer-events: none

            &:hover
                background-color: var(--gray500)
                padding-left: 2 * $unit - 0.5 * $unit
                padding-right: $unit + 0.5 * $unit

            // .HandshakeFilter__dropdown-item_text
            &_text
                +font-body-s
                user-select: none

    &__button-icon_down
        transition: all .4s
        margin-left: auto
        margin-right: -16px
