// Small tablets and large smartphones (landscape view)
$screen-xs-min: 320px

$screen-iphone12pro-min: 390px

// Small tablets and large smartphones (landscape view)
$screen-sm-min: 600px

// Small tablets (portrait view)
$screen-md-min: 978px

// Tablets and small desktops
$screen-lg-min: 1174px

// Large tablets and desktops
$screen-xl-min: 1590px

// Large desktops
// $screen-xl-min: 1901px;

$unit: 8px
@function Unit($n: 1)
    @return $n * $unit

@function HexToRGB($hex)
    @return red($hex), green($hex), blue($hex)

$size-m: Unit(6)
$size-l: Unit(7)

$height-m: $size-m
$height-l: $size-l

$borderRadius-s: 8px
$borderRadius-m: 10px
$borderRadius-l: 12px
