@mixin text-date {
  font-size: 12px;
  font-family: monospace;
  color: var(--black555);
}

@mixin fontText($size, $height, $weight) {
  font-family: var(--Inter);
  font-style: normal;
  font-weight: $weight;
  font-size: $size * 1px;
  line-height: $height * 1px;
  // letter-spacing: -0.02em;
}

@mixin fontText-xxxs($height: 14, $weight: 400) {
  @include fontText(11, $height, $weight)
}
@mixin fontText-xxs($height: 18, $weight: 400) {
  @include fontText(13, $height, $weight)
}
@mixin fontText-xs($height: 17, $weight: 400) {
  @include fontText(13, $height, $weight)
}
@mixin fontText-sm($height: 22, $weight: 400) {
  @include fontText(15, $height, $weight)
}
@mixin fontText-m-s($height: 20, $weight: 400) {
  @include fontText(15, $height, $weight)
}
@mixin fontText-m($height: 24, $weight: 400) {
  @include fontText(16, $height, $weight)
}
@mixin fontText-l($height: 26, $weight: 400) {
  @include fontText(18, $height, $weight)
}

@mixin fontText-m-s-bold($height: 22) {
  @include fontText-m-s($height, 700)
}
@mixin fontText-m-bold($height: 26) {
  @include fontText-m($height, 700)
}
@mixin fontText-l-bold($height: 28) {
  @include fontText-l($height, 700)
}



@mixin fontHX($size, $height, $weight: 700) {
  font-family: var(--TT);
  font-style: normal;
  font-weight: $weight;
  font-size: $size * 1px;
  line-height: $height * 1px;
  letter-spacing: -0.02em;
}

@mixin fontH5($height: 26) {
  @include fontHX(22, $height)
}


@mixin text-heading-h1 {
  font-family: var(--TT);
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 47px;
  letter-spacing: -0.02em;
}

@mixin text-heading-h2 {
  font-family: var(--TT);
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
  line-height: 72px;
  letter-spacing: -0.02em;
  text-align: center;
}

@mixin heading-h2 {
  font-family: var(--TT);
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 34px;
  letter-spacing: -0.02em;
}

@mixin text-heading-h3 {
  font-family: var(--TT);
  font-style: normal;
  font-weight: bold;
  font-size: 42px;
  line-height: 50px;
  letter-spacing: -0.02em;
}

@mixin text-heading-h4 {
  font-family: var(--TT);
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
}

@mixin text-heading-h5 {
  font-family: var(--TT);
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: -0.02em;
}

@mixin text-heading-h6 {
  font-family: var(--TT);
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  line-height: 24px;
}

@mixin text-body-l {
  font-family: var(--Inter);
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 25px;
}

@mixin text-body-m {
  font-family: var(--Inter);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

@mixin text-body-bold-m {
  font-family: var(--Inter);
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
}

@mixin text-body-s {
  font-family: var(--Inter);
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px !important;
}

@mixin text-body-sm {
  font-family: var(--Inter);
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
}

@mixin text-body-bold-s {
  font-family: var(--Inter);
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
}

@mixin text-body-xs {
  font-family: var(--Inter);
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
}

@mixin text-body-bold-xs {
  @include text-body-xs;
  font-weight: 700;
}

@mixin text-body-xxs {
  font-family: var(--Inter);
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 14px;
}

@mixin text-button-m {
  font-family: var(--Inter);
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
}

@mixin text-button-s {
  font-family: var(--Inter);
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
}

@mixin text-button-l {
  font-family: var(--Inter);
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.02em;
}
