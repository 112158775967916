@import 'assets/styles/mixins'

img[src=""]
  display: none

.relative
  position: relative

.white-space-pre-line
  white-space: pre-line

.wrap-normal
  white-space: normal !important

.paddingBottom128
  padding-bottom: 128px

.paddingBottom128-two_buttons
  padding-bottom: 128px
  @include screen_sm_max
    padding-bottom: 192px

.flex00a
  flex: 0 0 auto

.flex11a
  flex: 1 1 auto

.breakWord
  word-break: break-word

.btn-cta
  height: 56px
  background-color: var(--CTA) !important
  color: var(--white) !important
  font-size: 15px !important
  line-height: unset
  text-transform: none
  &:hover
    background-color: var(--CTAHover) !important

.CustomSelect
  .MuiSelect-root.MuiSelect-select
    color: var(--black)
    background: var(--gray400)
    border-radius: 12px
    margin: 3px 0px
    padding: 18.5px 28px 18.5px 14px !important

.FooterFixed
  position: fixed
  bottom: 0px
  margin-left: Unit(-2)
  padding: Unit(4) Unit(2)
  width: 100%
  display: flex
  flex-wrap: wrap
  gap: Unit(2)
  mask-image: linear-gradient(0deg, black 85%, transparent)
  background-color: var(--white)
  z-index: 1111
  +screen_sm_max
    margin-left: 0px
    left: 0px
    padding-bottom: Unit(3)
    padding-top: Unit(4)
    > *
      flex: 1 1 48%
      // width: 48%
