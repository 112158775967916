
=link
  font-size: 16px
  font-weight: 400
  line-height: 24px
  text-decoration: underline
  color: var(--primary)
  &:not(.--NotLink)
    cursor: pointer
    &:hover
      color: var(--primaryDarken)
      text-decoration: none

=link-not-underline
  +link
  text-decoration: none

=link-bold
  font-size: 16px
  font-weight: 700
  text-decoration: none
  color: var(--primary)
  &:not(.--NotLink)
    cursor: pointer
    &:hover
      color: var(--primaryDarken)
      text-decoration: underline

=links-silent
  text-decoration: none
  margin-left: -4px
  margin-right: -4px
  padding-left: 4px
  padding-right: 4px
  border-radius: 4px
  transition: .3s
  color: var(--link)
  &:not(.--NotLink)
    cursor: pointer
    &:hover
      background-color: var(--gray400)

=links-silent-underline
  +links-silent
  &:not(.--NotLink)
    &:hover
      text-decoration: underline

=links-right
  +link
  text-decoration: none
  transition: .3s
  svg > *
    stroke: var(--primaryDarken)
  &:not(.--NotLink)
    &:hover
      padding-left: $unit

=links-phone
  +link-not-underline
  color: var(--black)
