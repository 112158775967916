@import './variables'

=border-m
    border-radius: $borderRadius-m
=borderRadius-s
    border-radius: $borderRadius-s
=borderRadius-m
    border-radius: $borderRadius-m
=borderRadius-l
    border-radius: $borderRadius-m
=height-m
    height: $height-m
=height-l
    height: $height-l
